const pageName = { singular: 'Profile', plural: 'profile' }

export default [
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/pages/team/List.vue'),
    meta: {
      resource: 'Team',
      action: 'manage',
    },
  },
  {
    path: '/team/add',
    name: 'team',
    component: () => import('@/views/pages/team/Add.vue'),
    meta: {
      resource: 'Team',
      action: 'manage',
    },
  },
  {
    path: '/team/edit',
    name: 'team',
    component: () => import('@/views/pages/team/Edit.vue'),
    meta: {
      resource: 'Team',
      action: 'manage',
    },
  },
]
