import profile from './profile'
import rekeningBank from './rekening-bank'
import ekspedisi from './ekspedisi'
import pin from './pin'
import paymentSetting from './payment-setting'
import accessAccount from './access-account'

export default [
  ...profile, ...rekeningBank, ...ekspedisi, ...pin, ...paymentSetting, ...accessAccount,
]
